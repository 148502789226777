// eslint-disable-next-line import/prefer-default-export
export const onInitialClientRender = () => {
  if (window.Weglot && window.Weglot.initialize) {
    window.Weglot.initialize({
      api_key: window.location.origin.includes('be-org')
        ? 'wg_3959ba64dca1c36ab578c53b808cb43e8'
        : 'wg_682cbaa2df3ceea27fe26b8c0c82871d8',
      hide_switcher: true,
    });
    // const getSubdomain = window.location.host.split('.')[0];
    // const lang = getSubdomain === 'befactor' ? 'en' : getSubdomain;
    // window.Weglot.on('initialized', () => {
    //   window.localStorage.setItem('wglang', lang);
    //   window.Weglot.switchTo(lang);
    // });
  }
};
