// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-additional-guidelines-jsx": () => import("./../../../src/pages/additional-guidelines.jsx" /* webpackChunkName: "component---src-pages-additional-guidelines-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-contact-us-old-jsx": () => import("./../../../src/pages/contact-us-old.jsx" /* webpackChunkName: "component---src-pages-contact-us-old-jsx" */),
  "component---src-pages-gdpr-policy-jsx": () => import("./../../../src/pages/gdpr-policy.jsx" /* webpackChunkName: "component---src-pages-gdpr-policy-jsx" */),
  "component---src-pages-ibp-agreement-jsx": () => import("./../../../src/pages/ibp-agreement.jsx" /* webpackChunkName: "component---src-pages-ibp-agreement-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-learn-jsx": () => import("./../../../src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-life-at-be-jsx": () => import("./../../../src/pages/life-at-be.jsx" /* webpackChunkName: "component---src-pages-life-at-be-jsx" */),
  "component---src-pages-live-jsx": () => import("./../../../src/pages/live.jsx" /* webpackChunkName: "component---src-pages-live-jsx" */),
  "component---src-pages-mind-hub-jsx": () => import("./../../../src/pages/mind-hub.jsx" /* webpackChunkName: "component---src-pages-mind-hub-jsx" */),
  "component---src-pages-opportunity-jsx": () => import("./../../../src/pages/opportunity.jsx" /* webpackChunkName: "component---src-pages-opportunity-jsx" */),
  "component---src-pages-our-offices-jsx": () => import("./../../../src/pages/our-offices.jsx" /* webpackChunkName: "component---src-pages-our-offices-jsx" */),
  "component---src-pages-our-story-jsx": () => import("./../../../src/pages/our-story.jsx" /* webpackChunkName: "component---src-pages-our-story-jsx" */),
  "component---src-pages-page-2-jsx": () => import("./../../../src/pages/page-2.jsx" /* webpackChunkName: "component---src-pages-page-2-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-quantum-jsx": () => import("./../../../src/pages/quantum.jsx" /* webpackChunkName: "component---src-pages-quantum-jsx" */),
  "component---src-pages-quest-jsx": () => import("./../../../src/pages/quest.jsx" /* webpackChunkName: "component---src-pages-quest-jsx" */),
  "component---src-pages-shift-jsx": () => import("./../../../src/pages/shift.jsx" /* webpackChunkName: "component---src-pages-shift-jsx" */),
  "component---src-pages-wow-jsx": () => import("./../../../src/pages/wow.jsx" /* webpackChunkName: "component---src-pages-wow-jsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-documents-documents-jsx": () => import("./../../../src/templates/documents/documents.jsx" /* webpackChunkName: "component---src-templates-documents-documents-jsx" */)
}

